<template>
    <div class="ui-page">
        <div class="sd-head">
            <van-card
                :thumb="branch.logo || 'https://img01.yzcdn.cn/vant/ipad.jpeg'"
            >
                <template #title>
                    <div class="sd-head__title">{{branch.branchName}}</div>
                </template>
                <template #desc>
                    <div class="sd-head__desc">{{branch.simpleDesc}}</div>
                    <div class="sd-head__btn">
                        <van-icon class="gap-mr" name="star-o" />
                        <van-icon class="gap-mr" name="service-o" />
                        <van-icon name="share-o" />
                    </div>
                </template>
            </van-card>
        </div>
        <van-sticky>
            <van-tabs v-model="active" :style="{'height':tabHeight+'px'}">
                <van-tab title="商品" name="a">

                    <div class="sd-main flex-row" :style="{'height':(tabHeight-44)+'px'}">
                        <div class="sd-goods-nav">
                            <van-sidebar v-model="activeKey" @change="getGoodsList()">
                              <van-sidebar-item :title="item.className" v-for="item of classList" :key="item.classId"></van-sidebar-item>
                            </van-sidebar>
                        </div>
                        <div class="flex-item">
                            <van-card
                                v-for="(item, index) of goodsList" :key="index"
                                :desc="item.subTitle"
                                :title="item.goodsName"
                                :thumb="item.coverPic"
                                @click="toDetails(item.goodsId)"
                            >
                                <template #price>
                                  <div class="origin-price">
                                    <span>&yen;{{item.marketPrice}}</span>
                                  </div>
                                    <div class="flex-row flex-between">
                                        <div>
                                          &yen;{{item.salePrice}}
                                        </div>
                                        <van-stepper v-model="item.buyNum" theme="round" :min="0" button-size="22" disable-input  @plus="changeGoodsNum(item.goodsId,1)" @minus="changeGoodsNum(item.goodsId,0)" />
                                    </div>
                                </template>
                            </van-card>
                        </div>
                    </div>

                </van-tab>
                <van-tab title="评价" name="b">
                    <div class="sd-stars flex-row flex-between">
                        <div>
                            <div class="sd-stars__grade">4.0</div>
                            <div class="sd-stars__label">商家评分</div>
                        </div>
                        <div>
                            <div class="sd-stars__star"><van-rate v-model="stars" color="rgb(255, 210, 30)" readonly /></div>
                            <div class="sd-stars__label">店铺综合评分 共1人评价</div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="商家" name="c" v-html="branch.content">
                  {{branch.content}}
                </van-tab>
            </van-tabs>
        </van-sticky>
        <div class="sd-card">
            <div class="sd-card-bar">
                <div class="sd-card__card" @click="toShoppingCart">
                    <div class="sd-card__icon">
                        <van-badge :content="shoppingCartGoodsList.length">
                            <van-icon name="shopping-cart-o" size="28px" color="#fff" />
                        </van-badge>
                    </div>
                </div>
                <div class="flex-item flex-row flex-column-center">
<!--                    <div>-->
<!--                        <div class="sd-card__price">￥0.01</div>-->
<!--                        <div class="sd-card__text">配送费￥0.00</div>-->
<!--                    </div>-->
                </div>
                <div class="sd-card__btn" @click="toShoppingCart">
                    <span>去结算</span>
                </div>
            </div>
        </div>
<!--      <div class="back-home-container" @click="toHome">-->
<!--        <van-icon name="revoke" size="25px"/>-->
<!--      </div>-->
      <!--
    产品规格
    -->
      <van-sku
          v-model="showSku"
          :sku="sku"
          :goods="goodsObj"
          :goods-id="0"
          :quota="0"
          :quota-used="0"
          :hide-stock="sku.hide_stock"
          @buy-clicked="onBuyClicked"
          @add-cart="onAddCartClicked"
      />
    </div>
</template>

<script>
import storageUtils from "@/utils/storage";

export default {
    name: "ShopList",
    data() {
        return {
            active: 'a',
            tabHeight: 0,
            stars: 4,
            activeKey: 0,
            goodsNum: 0,
            branch:{
              branchId:0,
              branchName:'',
              logo:'',
              simpleDesc:'',
              contactMobile:'',
              content:''
            },
            classList:[],
            goodsList:[],
            goodsObj:{
              coverPic:'',
              picture:'',
              goodsName:'',
              marketPrice:'',
              salePrice:'0',
              saledCount:'',
              subTitle:'',
              contentArray:[]
            },
            showSku:false,
            sku: {
              // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
              // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
              tree: [],
              // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
              list: [],
              price: '1.00', // 默认价格（单位元）
              stock_num: 1, // 商品总库存
              // collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
              none_sku: false, // 是否无规格商品
              hide_stock: false // 是否隐藏剩余库存
            },
        }
    },
    computed:{
      shoppingCartGoodsList(){
        return this.$store.state.shoppingCartGoodsList;
      }
    },
  watch:{
    shoppingCartGoodsList(val){
      this.setBuyNum(val);
    }
  },
  created() {
      let branchId = this.$route.query.branchId;
      this.branch.branchId = branchId;
      this.getBranchInfo(branchId);
      this.getClassList(branchId);
  },
  methods: {
      async getBranchInfo(branchId){
        this.$axios.get('/api/wxmp/branch/info',{
          params:{
            branchId:branchId
          }
        }).then((res) =>{
          console.log('res',res);
          this.branch.branchName = res.data.branchName;
          this.branch.logo = res.data.logo;
          this.branch.simpleDesc = res.data.simpleDesc;
          this.branch.contactMobile = res.data.contactMobile;
          this.branch.content = res.data.content;
        }).catch((err) =>{
          console.log('err',err);
        })
      },
      getClassList(branchId){
        this.$axios.get('/api/wxmp/goods/class/list',{
          params:{
            branchId:branchId
          }
        }).then((res) =>{
          console.log('res',res);
          this.classList = res.data;
          if(this.classList.length > 0){
            this.getGoodsList();
          }
          console.log('this.classList',this.classList);
        }).catch((err) =>{
          console.log('err',err);
        })
      },
      getGoodsList(classId,branch){
        if(!classId){
          classId = this.classList[this.activeKey].classId;
        }
        console.log('getGoodsList - >',classId);
        console.log('this.branch.branchId',this.branch.branchId);
        this.$axios.get('/api/wxmp/goods/list',{
          params:{
            branchId:this.branch.branchId,
            classId:classId
          }
        }).then((res) =>{
          console.log('res',res);
          this.goodsList = res.data;
          this.setBuyNum(this.shoppingCartGoodsList);
        }).catch((err) =>{
          console.log('err',err);
        })
      },
      setHeight() {
            this.tabHeight = window.innerHeight - 50 - 113;
      },
      toHome(){
          this.$router.push('/')
      },
      toDetails(goodsId){
        this.$router.push({name:'shopDetails',query:{goodsId:goodsId}})
      },
      changeGoodsNum(goodsId,type){
        event.stopPropagation();
        if(type){
          //console.log('增加');
          //this.$store.commit("addShoppingCartGoods",{goodsId:goodsId,buyNum:1,itemId:0});
          this.getGoodsSku(goodsId);
        }else{
          //减少
          //console.log('减少');
          this.$store.commit("subShoppingCartGoods",{goodsId:goodsId,buyNum:1,itemId:0});
        }
      },
      getGoodsSku(goodsId){
        this.$axios.get('/api/wxmp/goods/details',{
          params:{
            goodsId:goodsId
          }
        }).then((res) =>{
          this.goodsObj.goodsId = res.data.goodsId
          this.goodsObj.coverPic = res.data.coverPic
          this.goodsObj.picture = res.data.coverPic
          this.goodsObj.goodsName = res.data.goodsName
          this.goodsObj.marketPrice = res.data.marketPrice
          this.goodsObj.salePrice = res.data.salePrice
          this.goodsObj.saledCount = res.data.saledCount
          this.goodsObj.subTitle = res.data.subTitle
          this.goodsObj.contentArray = res.data.contentArray
          this.sku.price = res.data.salePrice;
          //设置规格
          let childParentObj = {}
          //规格
          let standardArray = res.data.standardArray;
          if(!standardArray || !standardArray.length){
            this.sku.none_sku = true;
          }
          let tree = [];
          for (let i = 0; i < standardArray.length;i++){
            let standardObj = standardArray[i];
            let itemArray = standardArray[i].itemArray;
            let v = [];
            for (let s = 0 ;s < itemArray.length;s++){
              let itemObj = itemArray[s];
              v.push({
                id:itemObj.standardItemId+"",
                name:itemObj.standardItemName
              })
              childParentObj[itemObj.standardItemId] = standardObj.sId;
            }
            tree.push({
              k:standardObj.standardName,
              k_s:standardObj.sId+"",
              v:v
            })
          }
          this.sku.tree = tree;
          //商品
          let list = [];
          let standardJson = res.data.standardJson;
          for (let item in standardJson) {
            let value = standardJson[item];
            let keyArrays  = item.split("$");
            let obj = {
              id:value.itemId,
              price:(value.salePrice * 100),
              stock_num:value.stockNum
            }
            keyArrays.forEach(sItem =>{
              obj[childParentObj[sItem]] = sItem;
            })
            list.push(obj)
          }
          this.sku.list = list;
          this.showSku = true;
          //this.skuCache[goodsId] = this.sku;
        }).catch((err) =>{
          console.log('err',JSON.stringify(err));
        })
      },
      onBuyClicked(skuData){//立即购买
        this.showSku = false;
        //加入localStorege
        console.log('skuData',JSON.stringify(skuData));
        storageUtils.setItem("buyGoodsList",JSON.stringify([
          {goodsId:this.goodsObj.goodsId,buyNum:skuData.selectedNum,itemId:skuData.selectedSkuComb.id}
        ]))
        this.$router.push({name:'ShopSettle'});
      },
      onAddCartClicked(skuData){
        this.$store.commit("addShoppingCartGoods",{goodsId:this.goodsObj.goodsId,buyNum:skuData.selectedNum,itemId:skuData.selectedSkuComb.id});
        this.showSku = false;
      },
      toShoppingCart(){
        this.$router.push('/shoppingCart')
      },
      setBuyNum(shoppingCartGoodsList){
        this.goodsList.forEach(goodsItem =>{
          shoppingCartGoodsList.forEach(shoppingCartItem =>{
            if(shoppingCartItem.goodsId === goodsItem.goodsId){
              goodsItem.buyNum = shoppingCartItem.buyNum;
            }
          })
        })
      },
    },
    mounted() {
        this.setHeight()
        window.addEventListener('resize', this.setHeight)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setHeight)
    }
}
</script>

<style lang="scss" scoped>
.sd-head {
    background-color: rgb(17, 53, 51);
    color: #fff;

    &__title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    &__desc {
        color: rgba(255,255,255,0.6);
        margin-bottom: 12px;
    }

    &__btn {
        color: rgba(255,255,255,0.6);
        font-size: 24px;
        text-align: right;
    }
}

.sd-card {
    height: 50px;

    &__btn {
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $--color-primary;
        font-size: 18px;
    }

    &__price {
        font-size: 20px;
        color: #fff;
    }

    &__text {
        color: rgb(255,255,255,0.6);
    }

    &__card {
        display: flex;
        flex: 0 0 80px;
        max-width: 80px;
        justify-content: center;
    }

    &__icon {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: $--color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateY(-20px);
    }
}
.sd-card-bar {
    position: fixed;
    height: inherit;
    display: flex;
    background-color: #32312e;
    z-index: 28;
    bottom: 0;
    left: 0;
    right: 0;
}

.sd-stars {
    padding: 12px;
    background-color: #fff;

    &__label {
        color: #787878;
        font-size: 12px;
    }

    &__grade {
        font-size: 28px;
        color: #01c1aa;
    }

    &__star {
        font-size: 28px;
    }
}

.sd-main > div {
    overflow-y: auto;
}

.sd-goods-nav {
    background-color: #f7f8f9;
}
.back-home-container{
  position: fixed;
  left: 15px;
  bottom: 100px;
  width: 48px;
  height: 48px;
  color: #01c1aa;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px ;
}
.van-sidebar-item--select{
  color: $--color-primary;
}
</style>
