<template>
  <div>
    <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="selectAddress"
    />
  </div>
</template>

<script>
export default {
  name: "AddressList",
  data(){
    return{
      chosenAddressId:0,
      list: [],
      openid:this.$store.state.openid
    }
  },
  created() {
    let addressId = this.$route.query.addressId;
    if(addressId){
      this.chosenAddressId = addressId;
    }
    this.$axios.get('/api/wechatmini/children/address/list',{
      params:{
        openid:this.openid
      }
    }).then((res) =>{
      console.log('res',res);
      let list = [];
      res.data.forEach(item =>{
        list.push({
          id:item.addressId,
          name:item.relName,
          tel:item.mobile,
          address:item.provinceName + item.cityName +item.areaName +item.address,
          isDefault:item.defaultAddress === 1
        })
        if(this.chosenAddressId === 0 && item.defaultAddress){//默认选择默认的
          this.chosenAddressId = item.addressId;
        }
      })
      this.list = list;
      console.log('list',list);
    }).catch((err) =>{

    });
  },
  methods: {
    onAdd() {
      this.$router.push({name:'AddressEdit',query:{}})
    },
    onEdit(item, index) {
      this.$router.push({name:'AddressEdit',query:{addressId:item.id}})
    },
    selectAddress(item, index){
      this.$router.push({name:'ShopSettle',query:{addressId:item.id}})
    }
  }
}
</script>

<style scoped>

</style>
