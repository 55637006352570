<template>
  <div>
    <div class="goods-pic-container">
      <van-image fit="contain" :src="goodsObj.coverPic"></van-image>
    </div>
    <div class="goods-details-body">
      <p class="goods-name">{{goodsObj.goodsName}}</p>
      <p class="goods-desc">{{goodsObj.subTitle}}</p>
      <div class="goods-buy-body">
        <p class="goods-price">&yen;{{goodsObj.salePrice}}<span class="origin-price">&yen;{{goodsObj.marketPrice}}</span></p>
        <div class="to-shopping-cart">
<!--          <van-stepper v-model="buyNum" theme="round" step="1" button-size="20px" integer v-if="buyNum"/>-->
<!--          <van-button icon="plus" round color="#01c1aa" size="mini" v-else @click="addShoppingCart">加入购物车</van-button>-->
        </div>
      </div>
    </div>
    <van-divider />
    <div class="goods-content-container">
      <p class="goods-content-title">商品描述</p>
      <div class="goods-content">
          <div v-for="(item,index) in goodsObj.contentArray" :key="index">
            <img :src="item.str" alt="" v-if="item.type === 2" />
            <p v-if="item.type === 1" >{{item.str}}</p>
          </div>
      </div>
    </div>
    <div class="sd-card">
      <div class="sd-card-bar">
        <div class="sd-card__card" @click="toShoppingCart">
          <div class="sd-card__icon">
            <van-badge :content="shoppingCartGoodsList.length">
              <van-icon name="shopping-cart-o" size="28px" color="#fff" />
            </van-badge>
          </div>
        </div>
        <div class="flex-item flex-row flex-column-center">
          <div>
            <div class="sd-card__price">￥{{goodsObj.salePrice * buyNum}}</div>
            <div class="sd-card__text">配送费￥0.00</div>
          </div>
        </div>
        <div class="sd-card__btn" @click="gotoBuy">
          <span>立即购买</span>
        </div>
      </div>
    </div>
    <van-sku
        v-model="showSku"
        :sku="sku"
        :goods="goodsObj"
        :goods-id="0"
        :quota="0"
        :quota-used="0"
        :hide-stock="sku.hide_stock"
        @buy-clicked="onBuyClicked"
        @add-cart="onAddCartClicked"
    />
  </div>
</template>
<script>
import storageUtils from '../../utils/storage'
export default {
  name: "ShopDetails",
  data() {
    return {
        goodsId:0,
        goodsObj:{
          coverPic:'',
          picture:'',
          goodsName:'',
          marketPrice:'',
          salePrice:'0',
          saledCount:'',
          subTitle:'',
          contentArray:[]
        },
        buyNum:1,
        itemId:0,
        showSku:false,
        sku: {
          // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
          // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
          tree: [],
          // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
          list: [],
          price: '1.00', // 默认价格（单位元）
          stock_num: 1, // 商品总库存
          // collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
          none_sku: false, // 是否无规格商品
          hide_stock: false // 是否隐藏剩余库存
        }
    }
  },
  created() {
    this.goodsId = this.$route.query.goodsId;
    this.getGoodsInfo(this.goodsId);
  },
  computed:{
    shoppingCartGoodsList(){
      console.log(this.$store.state.shoppingCartGoodsList);
     return this.$store.state.shoppingCartGoodsList;
    }
  },
  methods:{
    getGoodsInfo(goodsId){
      this.$axios.get('/api/wxmp/goods/details',{
        params:{
          goodsId:goodsId
        }
      }).then((res) =>{
        this.goodsObj.coverPic = res.data.coverPic
        this.goodsObj.picture = res.data.coverPic
        this.goodsObj.goodsName = res.data.goodsName
        this.goodsObj.marketPrice = res.data.marketPrice
        this.goodsObj.salePrice = res.data.salePrice
        this.goodsObj.saledCount = res.data.saledCount
        this.goodsObj.subTitle = res.data.subTitle
        this.goodsObj.contentArray = res.data.contentArray
        this.sku.price = res.data.salePrice;

        //设置规格
          let childParentObj = {}
          //规格
          let standardArray = res.data.standardArray;
          if(!standardArray || !standardArray.length){
            this.sku.none_sku = true;
          }
          let tree = [];
          for (let i = 0; i < standardArray.length;i++){
            let standardObj = standardArray[i];
            let itemArray = standardArray[i].itemArray;
            let v = [];
            for (let s = 0 ;s < itemArray.length;s++){
                let itemObj = itemArray[s];
                v.push({
                  id:itemObj.standardItemId+"",
                  name:itemObj.standardItemName
                })
              childParentObj[itemObj.standardItemId] = standardObj.sId;
            }
            tree.push({
              k:standardObj.standardName,
              k_s:standardObj.sId+"",
              v:v
            })
          }
          this.sku.tree = tree;
          //商品
          let list = [];
          let standardJson = res.data.standardJson;
          for (let item in standardJson) {
              let value = standardJson[item];
              let keyArrays  = item.split("$");
              let obj = {
                id:value.itemId,
                price:(value.salePrice * 100),
                stock_num:value.stockNum
              }
              keyArrays.forEach(sItem =>{
                obj[childParentObj[sItem]] = sItem;
              })
              list.push(obj)
          }
          this.sku.list = list;
        }).catch((err) =>{
          console.log('err',err);
        })
    },
    gotoBuy(){
      this.buyNum = 1;
      this.showSku = true;
    },
    toShoppingCart(){
      this.$router.push('/shoppingCart')
    },
    onBuyClicked(skuData){//立即购买
      this.showSku = false;
      this.buyNum = skuData.selectedNum;
      this.itemId = skuData.selectedSkuComb.id;
      this.showSku = false;
      //加入localStorege
      console.log('skuData',JSON.stringify(skuData));
      storageUtils.setItem("buyGoodsList",JSON.stringify([
          {goodsId:this.goodsId,buyNum:this.buyNum,itemId:this.itemId}
      ]))
      this.$router.push({name:'ShopSettle'});
    },
    onAddCartClicked(skuData){
      this.$store.commit("addShoppingCartGoods",{goodsId:this.goodsId,buyNum:skuData.selectedNum,itemId:skuData.selectedSkuComb.id});
      this.showSku = false;
    }
  }
}
</script>
<style lang="scss" scoped>
  p{
    margin: 0;
    padding: 0;
  }
  .goods-pic-container{

  }
  .goods-details-body{
    padding: 5px;
  }
  .goods-name{
    font-size: 16px;
    font-weight: bolder;
    padding: 10px 0;
  }
  .goods-desc{
    font-size: 12px;
    color: #666;
  }
  .goods-buy-body{
      margin: 5px 0;
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      align-items: center;
  }
  .goods-price{
    font-size: 18px;
    color: red;
  }
  .to-shopping-cart{
    padding-right: 10px;
  }
  .goods-content-container{

  }
  .goods-content-title{
    padding: 10px 5px;
    font-size: 16px;
    font-weight: bolder;
  }
  .goods-content{
    max-width: 100%;
    padding: 0 5px;
  }
  .goods-content img{
    max-width: 100% !important;
  }
  .van-divider{
    margin: 5px 0;
  }
  .sd-card {
    height: 50px;

    &__btn {
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #01c1aa;
      font-size: 18px;
    }

    &__price {
      font-size: 20px;
      color: #fff;
    }

    &__text {
      color: rgb(255,255,255,0.6);
    }

    &__card {
      display: flex;
      flex: 0 0 80px;
      max-width: 80px;
      justify-content: center;
    }

    &__icon {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: #01c1aa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: translateY(-20px);
    }
  }
  .sd-card-bar {
    position: fixed;
    height: inherit;
    display: flex;
    background-color: #32312e;
    z-index: 28;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .sd-stars {
    padding: 12px;
    background-color: #fff;

    &__label {
      color: #787878;
      font-size: 12px;
    }

    &__grade {
      font-size: 28px;
      color: #01c1aa;
    }

    &__star {
      font-size: 28px;
    }
  }
  .origin-price{
    padding-left: 5px;
  }
</style>
