<template>
  <div>
    <!--
      <van-card
  num="2"
  price="2.00"
  desc="描述信息"
  title="商品标题"
  thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
/>
    -->
    <div class="shopping-cart-header">
        <p class="shopping-cart-title">
          购物车
        </p>
      <p class="shopping-cart-goods-num">
        共计{{goodsList.length}}件商品
      </p>
    </div>
    <div class="goods-list-container">
      <div class="goods-list">
        <div class="goods-item" v-for="(item,index) in goodsList" :key="index">
          <div class="goods-img">
            <img :src="item.coverPic" alt="">
          </div>
          <div class="goods-info">
            <div class="goods-name">
              <p>{{item.goodsName}}</p>
              <p class="goods-standard">
                <span v-for="(item,index) in item.itemArray" :key="index">{{item}}</span>
              </p>
            </div>
            <div class="goods-price">
              <p class="price">&yen;{{item.salePrice}}</p>
              <van-stepper @change="goodsChange(index,1)" v-model="item.buyNum" button-size="20px" disable-input />
            </div>
          </div>
          <div class="select-checkbox">
            <van-checkbox v-model="item.selected" checked-color="#01c1aa"></van-checkbox>
          </div>
          <div class="delete-goods">
            <van-icon name="delete" color="red" size="20px" @click="goodsChange(index,0)"/>
          </div>
        </div>
      </div>
    </div>
    <van-submit-bar :price="price" :disabled="buyGoodsList.length === 0" button-text="立即购买" button-color="#01c1aa" @submit="onSubmit">
      <van-checkbox v-model="allChecked" @click.stop="checkAllClick" checked-color="#01c1aa">全选</van-checkbox>
    </van-submit-bar>
  </div>
</template>

<script>
import storageUtils from "@/utils/storage";

export default {
  name: "ShoppingCart",
  data(){
    return {
      checked:false,
      goodsList:[],
    }
  },
  computed:{
    price(){
      let price = 0;
      this.goodsList.forEach(item =>{
        if(item.selected){
          price += Number(item.salePrice)  * 100 * item.buyNum;
        }
      })
      return price;
    },
    allChecked:{
      get(){
        let allChecked = true;
        this.goodsList.forEach(item =>{
          if(!item.selected){
            allChecked = false;
          }
        })
        return allChecked;
      },
      set(){

      }
    },
    buyGoodsList(){
      let list = [];
      this.goodsList.forEach(item =>{
        if(item.selected){
          console.log('item',item);
          list.push(
              {goodsId:item.goodsId,buyNum:item.buyNum,itemId:item.itemId}
          )
        }
      })
      return list;
    }
  },
  watch:{
    goodsList(val){
      console.log('val',JSON.stringify(val));
    }
  },
  created() {
    let shoppingCartGoodsList = this.$store.state.shoppingCartGoodsList;
    if(shoppingCartGoodsList && shoppingCartGoodsList.length){
      this.getGoodsListInfo(shoppingCartGoodsList);
    }
  },
  methods:{
    onSubmit(){//提交订单
      console.log('this.buyGoodsList',JSON.stringify(this.buyGoodsList));
      storageUtils.setItem("buyGoodsList",JSON.stringify(this.buyGoodsList))
      this.$router.push({name:'ShopSettle'});
    },
    getGoodsListInfo(shoppingCartGoodsList){
      console.log('shoppingCartGoodsList',shoppingCartGoodsList);
      this.$axios.post("/api/wxmp/goods/goodsListInfo",shoppingCartGoodsList).then((res) =>{
        res.goodsArray.forEach(item =>{
          item.selected = true;
        })
        this.goodsList = res.goodsArray;

      }).catch((err) =>{
        console.log('err',err);
      })
    },
    checkAllClick(){
      if(this.allChecked){
        this.goodsList.forEach(item =>{
          item.selected = false;
        })
      }else{
        this.goodsList.forEach(item =>{
          item.selected = true;
        })
      }
    },
    goodsChange(index,type){
      let shoppingCartGoodsList = [];
      this.goodsList.forEach(item =>{
        shoppingCartGoodsList.push(
            {goodsId:item.goodsId,buyNum:item.buyNum,itemId:item.itemId}
        )
      });
      if(type === 0){//更新数量
        this.$dialog.confirm({
          title: '提示',
          message: '是否确认删除该商品？',
        }).then(() => {
              // on confirm
            shoppingCartGoodsList.splice(index,1);
            this.goodsList.splice(index,1);
          this.$store.commit("updateShoppingCartGoods",shoppingCartGoodsList)
            }).catch(() => {
              // on cancel
            });
      }else{
        this.$store.commit("updateShoppingCartGoods",shoppingCartGoodsList)
      }
    }
  }
}
</script>

<style scoped>
  p{
    margin: 0;
    padding: 5px 0;
  }
  .shopping-cart-header{
    width: 100%;
    height: 100px;
    background-color: #01c1aa;
    color: #fff;
    padding: 10px;
  }
  .shopping-cart-title{
    font-size: 14px;
    font-weight: bolder;
  }
  .shopping-cart-goods-num{
    font-size: 12px;
    margin-top: 10px;
  }
  .goods-standard{
    color: #666;
    font-size: 12px;
  }
  .goods-list-container{
    margin-top: -20px;
    padding: 0 10px;
  }
  .goods-list{
    border-radius: 5px;
    overflow: hidden;
  }
  .goods-item{
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fafafa;
    padding-top:10px ;
  }
  .goods-item +.goods-item{
    margin: 10px 0;
  }
  .goods-img{
    max-width: 200px;
    padding: 0 20px 0 35px;
  }
  .goods-img img{
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
  }
  .goods-info{
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .goods-price{
    color: red;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .goods-price > .price{
    padding-right: 20px;
    width: 80px;
    overflow: hidden;
  }
  .delete-goods{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-checkbox{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
