<template>
  <div>
    <van-address-edit
        :area-list="areaList"
        :show-delete="addressId > 0"
        show-set-default
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        :address-info="addressInfo"
    />
  </div>
</template>

<script>
import areaArray from '../../utils/pca-code.json'
export default {
  name: "AddressEdit",
  data() {
    return {
      areaList:{
        province_list: {

        },
        city_list: {},
        county_list: {}
      },
      addressId:0,
      addressInfo:{

      },
      openid:this.$store.state.openid
    };
  },
  created() {
    this.addressId = this.$route.query.addressId;
    if(this.addressId){
      this.getAddress(this.addressId);
    }else{
      this.addressId = 0;
    }
    areaArray.forEach(item =>{
      this.areaList.province_list[item.code] = item.name;
      item.children.forEach((child) =>{
        this.areaList.city_list[child.code] = child.name;
        child.children.forEach(aObj =>{
          this.areaList.county_list[aObj.code] = aObj.name;
        })
      })
    });
  },
  methods: {
    getAddress(addressId){
      this.$axios.get("/api/wechatmini/children/address",{
        params:{
          addressId:addressId,
          openid:this.openid
        }
      }).then((res) =>{
        let address = res.data;
        this.addressInfo = {
          id:address.addressId,
          name:address.relName,
          tel:address.mobile,
          province:address.address,
          city:address.city,
          county:address.county,
          addressDetail:address.address,
          areaCode:address.area,
          isDefault:address.defaultAddress === 1
        }
      }).catch((res) =>{
        console.log(res);
      })
    },
    onSave(content) {
      this.$axios.postForm("/api/wechatmini/children/address/update",{
        addressId:this.addressId,
        openid:this.openid,
        relName:content.name,
        areaCode:content.areaCode,
        address:content.addressDetail,
        mobile:content.tel,
        defaultAddress:content.isDefault?1:0
      }).then((res) =>{
        if(res.code === 0){
          this.$toast.success('操作成功');
          setTimeout(() =>{
            this.$router.push({'name':'AddressList'})
          },1000)
        }
      }).catch((err) =>{
        console.log(err);
      })
    },
    onDelete(content) {
      this.$axios.postForm("/api/wechatmini/children/address/delete",{
        addressId:content.id
      }).then((res) =>{
        console.log('res',res)
        this.$toast.success('操作成功');
        setTimeout(() =>{
          this.$router.push({'name':'AddressList'})
        },1000)
      }).catch((err) =>{
        console.log(err);
      })
    },
  },
}
</script>

<style scoped>

</style>
