<template>
  <div style="background-color:#fafafa;">
    <div class="address-select" @click="toAddressList">
      <van-icon name="location" color="#01c1aa" size="20px"/>
      <div class="select-address-tips">
        <span v-if="addressId === 0">请选择地址</span>
        <div class="address-info" v-else>
          <div class="name-mobile"><span>{{addressInfo.relName}}</span><span>{{addressInfo.mobile}}</span></div>
          <div class="address-details"> {{addressInfo.provinceName + addressInfo.cityName + addressInfo.areaName +addressInfo.address}}</div>
        </div>

      </div>
    </div>
    <van-card
        :title="item.goodsName"
        :thumb="item.coverPic"
        v-for="(item,index) in buyGoods" :key="index"
    >
      <template #price>
        <div class="goods-price-container">
          <div class="goods-price">
            <span class="sale-price">&yen;{{item.salePrice}}</span>
          </div>
          <div class="buy-num">
            x{{item.buyNum}}
          </div>
        </div>
      </template>
      <template #desc>
        <div class="goods-desc">
          <span v-if="item.itemArray.length === 0">标准规格</span>
          <span v-for="sItem in item.itemArray" :key="sItem">{{sItem}}</span>
        </div>
      </template>
    </van-card>
    <van-cell-group>
      <van-cell title="总金额" :value="totalPrice" value-class="total-price" />
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="orderRemark" label="订单备注" placeholder="请输入留言" />
    </van-cell-group>
    <van-submit-bar :price="price" :loading="loading" :disabled="addressId === 0" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
import storageUtils from '../../utils/storage'
export default {
  name: "ShopSettle",
  data() {
    return {
      buyGoods:[],
      addressId:0,
      address:{

      },
      loading:false,
      openid:this.$store.state.openid,
      orderRemark:'',
      totalPrice:'',
      price:0
    }
  },
  created() {
    let buyGoodsList = JSON.parse(storageUtils.getItem("buyGoodsList","[]"));
    if(!buyGoodsList || !buyGoodsList.length){
      this.$toast.fail("无结算商品");
    }
    console.log('buyGoodsList',JSON.stringify(buyGoodsList));
    let selectAddreessId = this.$route.query.addressId;
    if(selectAddreessId){
      this.getAddress(selectAddreessId);
    }else{
      this.getAddress(this.addressId);
    }
    this.getBuyGoodsInfo(buyGoodsList);
  },
  methods:{
    onSubmit(){
      this.loading = true;
      let goodsList = [];
      this.buyGoods.forEach(item =>{
        goodsList.push(
            {
              tuanGoodsId:item.goodsId,
              goodsName:item.goodsName,
              itemId:item.itemId,
              saleNum:item.buyNum,
              branchId:item.branchId
            }
        )
      });
      this.$axios.postForm('/api/wxmp/goods/createOrder',{
        openid:this.openid,
        addressId:this.addressId,
        goodsStr:JSON.stringify(goodsList)
      }).then((res) =>{
        console.log('res',res);
        if(res.code === 0){
          let orderId = res.orderId;
          //调起支付
          this.gotoPay(orderId);
        }
      }).catch((err) =>{
        console.log('err',err);
        this.$toast.fail(err.msg);
        this.loading = false;
      })
    },
    gotoPay(orderId){
      this.$axios.postForm("/api/wxmp/goods/order/getWechatPayData",{
        orderId:orderId,
        openid:this.openid
      }).then((res) =>{
        let that = this;
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
              "appId": res.data.appId,
              //公众号名称，由商户传入
              "timeStamp": res.data.timeStamp,
              //时间戳，自1970年以来的秒数
              "nonceStr": res.data.nonceStr,
              //随机串
              "package": res.data.package,
              "signType": res.data.signType,
              //微信签名方式：
              "paySign": res.data.paySign //微信签名
            },
            function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                that.$toast.success('支付成功');
                setTimeout(() =>{
                  that.$router.push("/orderList")
                },1000)
              }else{
                that.$toast.fail('支付失败');
              }
            });
      }).catch((err) =>{
        this.$toast.fail('支付失败...');
      })
    },
    toAddressList(){
      this.$router.push({name:'AddressList',query:{addressId:this.addressId}})
    },
    getAddress(addressId){
      this.$axios.get("/api/wechatmini/children/address",{
        params:{
          addressId:addressId,
          openid:this.openid
        }
      }).then((res) =>{
        console.log('add',JSON.stringify(res));
        if(res.code === 0){
            this.addressId = res.data.addressId;
            this.addressInfo = res.data
        }
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    getBuyGoodsInfo(buyGoodsList){
      this.$axios.post("/api/wxmp/goods/goodsListInfo",buyGoodsList).then((res) =>{
        let goodsArray = res.goodsArray;
        if(!goodsArray || !goodsArray.length){
          this.$toast.fail("无结算商品");
        }
        this.buyGoods = goodsArray;
        this.totalPrice = res.totalPrice;
        this.price = Number(this.totalPrice) * 100;
      }).catch((err) =>{
        console.log('err',err);
      })
    }
  }
}
</script>

<style scoped>
  .address-select{
    color: black;
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items:center;
    border-bottom: 1px solid #ebedf0;
  }
  .address-select:before{
    content:'';
    width: 10px;
    height: 10px;
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  .select-address-tips{
      margin-left: 10px;
  }
  .address-details{
    color: #666;
    margin-top: 6px;
    font-size: 14px;
  }
  .address-info{
    font-size: 16px;
  }
  .name-mobile span{
    padding-right: 10px;
  }
  .goods-price-container{
    display: flex;
    justify-content: space-between;
  }
  .goods-price{
    color: red;
    font-size: 14px;
  }
  .sale-price{
    padding-right: 5px;
  }
  .goods-desc{
    margin-top: 5px;
    color: #666;
  }
  .total-price{
    color: red;
  }
  .van-card{
    background-color: #fafafa;
  }
</style>
